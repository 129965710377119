



















import Vue, { VueConstructor } from 'vue';
import { MetaInfo } from 'vue-meta';
import AdminImportPartnerService from '@/services/admin/AdminImportPartnerService';
import ToastMessage from '@/models/ToastMessage';

export default (Vue as VueConstructor<Vue>).extend({
  data() {
    return {
      importGutshofOrdersIsLoading: false,
      importGutshofVouchersIsLoading: false,
      deleteGutshofOrdersIsLoading: false,
      deleteGutshofVouchersIsLoading: false,
    };
  },
  metaInfo(): MetaInfo {
    return {
      title: this.$t('pages.admin.AdminPartnerIndex.meta.title').toString(),
    };
  },
  methods: {
    importGutshofOrders() {
      this.importGutshofOrdersIsLoading = true;
      AdminImportPartnerService.importGutshofOrders().then(
        () => {
          this.$store.commit(
            'addToastMessage',
            new ToastMessage(this.$t('forms.common.save_successful').toString(), 'bg-success')
          );
          this.importGutshofOrdersIsLoading = false;
        },
        () => {
          this.$store.commit(
            'addToastMessage',
            new ToastMessage(this.$t('forms.common.save_error').toString(), 'bg-danger')
          );
          this.importGutshofOrdersIsLoading = false;
        }
      );
    },
    deleteGutshofOrders() {
      this.deleteGutshofOrdersIsLoading = true;
      AdminImportPartnerService.deleteGutshofOrders().then(
        () => {
          this.$store.commit(
            'addToastMessage',
            new ToastMessage(this.$t('forms.common.save_successful').toString(), 'bg-success')
          );
          this.deleteGutshofOrdersIsLoading = false;
        },
        () => {
          this.$store.commit(
            'addToastMessage',
            new ToastMessage(this.$t('forms.common.save_error').toString(), 'bg-danger')
          );
          this.deleteGutshofOrdersIsLoading = false;
        }
      );
    },
    importGutshofVouchers() {
      this.importGutshofVouchersIsLoading = true;
      AdminImportPartnerService.importGutshofVouchers().then(
        () => {
          this.$store.commit(
            'addToastMessage',
            new ToastMessage(this.$t('forms.common.save_successful').toString(), 'bg-success')
          );
          this.importGutshofVouchersIsLoading = false;
        },
        () => {
          this.$store.commit(
            'addToastMessage',
            new ToastMessage(this.$t('forms.common.save_error').toString(), 'bg-danger')
          );
          this.importGutshofVouchersIsLoading = false;
        }
      );
    },
    deleteGutshofVouchers() {
      this.deleteGutshofVouchersIsLoading = true;
      AdminImportPartnerService.deleteGutshofVouchers().then(
        () => {
          this.$store.commit(
            'addToastMessage',
            new ToastMessage(this.$t('forms.common.save_successful').toString(), 'bg-success')
          );
          this.deleteGutshofVouchersIsLoading = false;
        },
        () => {
          this.$store.commit(
            'addToastMessage',
            new ToastMessage(this.$t('forms.common.save_error').toString(), 'bg-danger')
          );
          this.deleteGutshofVouchersIsLoading = false;
        }
      );
    },
  },
});
